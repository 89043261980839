import styles from "./SvgIconImageContainer.module.scss";

type SvgIconImageContainerProps = {
  icon: any;
  title: string;
};

export const SvgIconImageContainer = (props: SvgIconImageContainerProps) => {
  return (
    <div className={styles.container}>
      <img src={props.icon} alt={props.title} />
    </div>
  );
};
