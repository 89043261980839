import styles from "./SideAppBar.module.scss";
import { Backdrop } from "../../common/Backdrop/Backdrop";
import { getUserRoutesByRole } from "../../../routes/routes";
import { NavigationItems } from "../NavigationItems/NavigationItems";

type SideAppBarProps = {
  isSideBarOpened: boolean;
  closeSideBar: () => void;
};

export const SideAppBar = (props: SideAppBarProps) => {
  // todo add expend when user roles will be integrated
  const userRoutes = getUserRoutesByRole("Visitor");

  return (
    <>
      <Backdrop
        show={props.isSideBarOpened}
        closeBackdrop={props.closeSideBar}
      />
      <div
        className={`${styles.container} ${
          props.isSideBarOpened ? styles.openSideBar : styles.closeSideBar
        }`}
      >
        <NavigationItems
          navBarItems={userRoutes}
          closeSideBar={props.closeSideBar}
        />
      </div>
    </>
  );
};
