import { useEffect, useState } from "react";
import firebaseAppClient from "../core/firebase.config";
import { getDocs } from "firebase/firestore";
import { DEFAULT_SERVICES } from "../screens/Home/Home.utils";

type Service = {
  title: string;
  description: string;
  specifications?: string[];
};

export type ServicesResponse = Service[];

export const useGetServices = () => {
  const [services, setServices] = useState<ServicesResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const servicesCol = firebaseAppClient.getCollection("services");
    getDocs(servicesCol)
      .then((servicesColResponse) => {
        const availableServices = servicesColResponse.docs.map((servicesItem) =>
          servicesItem.data()
        ) as unknown as Service[];
        if (availableServices.length > 0) {
          setServices(availableServices);
        } else {
          setServices(DEFAULT_SERVICES);
        }
      })
      .catch(() => {
        setServices(DEFAULT_SERVICES);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { services, isLoading };
};
