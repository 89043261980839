import { Environment } from "./environment";

export const devEnv: Environment = {
  apiUrl: "https://blognodejsbackend.herokuapp.com",
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID ?? "",
    appId: process.env.REACT_APP_FIREBASE_APP_ID ?? "",
    measurementId: process.env.REACT_APP_MEASUREMENT_ID ?? "",
  },
};
