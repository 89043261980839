import styles from "./ServiceCard.module.scss";
import apiIcon from "../../../assests/home/apiIcon.svg";
import frontendIcon from "../../../assests/home/frontendIcon.svg";
import backendIcon from "../../../assests/home/backendIcon.svg";
import fullStackIcon from "../../../assests/home/fullstackIcon.svg";
import serviceSpecification from "../../../assests/home/serviceCardSpecification.svg";
import { CONTACT_ME_ID, scrollToContainer } from "../Home";

export type ServiceCardProps = {
  title: string;
  description: string;
  specifications?: string[];
};

export const getImageIcon = (cardTitle: string) => {
  const editedCardTitle = cardTitle.toLocaleLowerCase();
  if (editedCardTitle.startsWith("front-end")) {
    return frontendIcon;
  } else if (editedCardTitle.startsWith("back-end")) {
    return backendIcon;
  } else if (editedCardTitle.startsWith("web")) {
    return fullStackIcon;
  } else if (editedCardTitle.startsWith("api")) {
    return apiIcon;
  } else {
  }
  return "";
};

export const getTruncatedText = (
  text: string,
  characters: number,
  limit: number
) => {
  if (text.length > limit) {
    return (text ?? "").substring(0, characters) + "...";
  } else {
    return text;
  }
};

export const ServiceCard = (props: ServiceCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <div className={styles.headingImageContainer}>
          <img src={getImageIcon(props.title)} alt={props.title} />
        </div>
        <h4>{props.title}</h4>
      </div>
      <div className={styles.cardDivider} />
      <div className={styles.descriptionContainer}>
        <div className={styles.cardDescription}>
          {getTruncatedText(props.description, 200, 200)}
        </div>
        <div className={styles.serviceSpecificationContainer}>
          <ul>
            {props.specifications?.map((specification) => {
              return (
                <span
                  key={`spec-${specification}`}
                  className={styles.serviceItem}
                >
                  <img alt={"specification icon"} src={serviceSpecification} />
                  <li>{specification}</li>
                </span>
              );
            })}
          </ul>
        </div>
      </div>
      <div
        className={styles.contactContainer}
        onClick={() => {
          scrollToContainer(CONTACT_ME_ID);
        }}
      >
        Contact me
      </div>
    </div>
  );
};
