import styles from "./NavigationItems.module.scss";
import { Route } from "../../../routes/routes";
import { useLocation, useNavigate, NavLink } from "react-router-dom";

type NavigationItemsProps = {
  navBarItems: Route[];
  closeSideBar?: () => void;
};

export const NavigationItems = (props: NavigationItemsProps) => {
  return (
    <nav className={styles.container}>
      <ul className={styles.navigationItemsListContainer}>
        {props.navBarItems.map((navBarItem) => {
          return (
            <NavigationItem
              key={navBarItem.name}
              {...navBarItem}
              closeSideBar={props.closeSideBar}
            />
          );
        })}
      </ul>
    </nav>
  );
};

type NavigationItemProps = Route & {
  closeSideBar?: () => void;
};

const NavigationItem = (props: NavigationItemProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isRouteActive = location.pathname === props.link;

  const navigateToPage = () => {
    navigate(props.link);
    if (props.closeSideBar) {
      props?.closeSideBar();
    }
  };

  return props.isButton ? (
    <li onClick={navigateToPage} className={styles.navigationItemButton}>
      <NavLink
        to={props.link}
        onClick={() => {
          if (props.closeSideBar) {
            props?.closeSideBar();
          }
        }}
      >
        {props.name}
      </NavLink>
    </li>
  ) : (
    <li
      onClick={navigateToPage}
      className={`${styles.navigationItem} ${
        isRouteActive ? styles.activeNavigationItemRoute : ""
      }`}
    >
      <NavLink
        to={props.link}
        onClick={() => {
          if (props.closeSideBar) {
            props?.closeSideBar();
          }
        }}
      >
        {props.name}
      </NavLink>
    </li>
  );
};
