import styles from "./ContactLink.module.scss";
import phoneIcon from "../../../assests/home/phoneIcon.svg";
import emailIcon from "../../../assests/home/emailIcon.svg";
import locationIcon from "../../../assests/home/location.svg";

export type ContactType = "location" | "phone" | "email";

export type ContactInfo = {
  name: string;
  type: ContactType;
  data: string;
};

export type ContactLinkProps = {
  contactDetails: ContactInfo;
};

const getContactTypeIcon = (contactType: ContactType) => {
  switch (contactType) {
    case "email": {
      return emailIcon;
    }
    case "phone": {
      return phoneIcon;
    }
    case "location": {
      return locationIcon;
    }
    default: {
      return undefined;
    }
  }
};

export const ContactLink = (props: ContactLinkProps) => {
  let contactComponent: any = null;

  switch (props.contactDetails.type) {
    case "phone":
      contactComponent = (
        <a href={"tel:" + props.contactDetails.data}>
          + {props.contactDetails.data}
        </a>
      );
      break;
    case "email":
      contactComponent = (
        <a href={"mailto:" + props.contactDetails.data}>
          {props.contactDetails.data}
        </a>
      );
      break;
    case "location":
      contactComponent = (
        <a
          href={"http://maps.google.com/maps?q=" + props.contactDetails.data}
          rel="noopener noreferrer"
          target="_blank"
        >
          {props.contactDetails.data}
        </a>
      );
      break;
    default:
      contactComponent = (
        <a href={"tel:" + props.contactDetails.data}>
          {props.contactDetails.data}
        </a>
      );
  }
  return (
    <span className={styles.container}>
      <img
        src={getContactTypeIcon(props.contactDetails.type)}
        alt={props.contactDetails.data}
      />
      {contactComponent}
    </span>
  );
};
