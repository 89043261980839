import styles from "./BasicFreeTextField.module.scss";
import { HTMLInputTypeAttribute } from "react";
import { useFormContext, RegisterOptions } from "react-hook-form";

export type BasicFreeTextFieldProps = {
  name: string;
  label?: string;
  placeHolder?: string;
  fieldType?: HTMLInputTypeAttribute;
  registerOptions?: RegisterOptions;
};

export const BasicFreeTextField = (props: BasicFreeTextFieldProps) => {
  const { name, placeHolder, fieldType, registerOptions } = props;
  const formContext = useFormContext();
  const error = formContext.formState.errors[name]?.message?.toString() ?? "";
  const fieldClasses = [styles.inputField];

  if (error) {
    fieldClasses.push(styles.inputFieldValidationError);
  }

  return (
    <div className={styles.container}>
      {error && (
        <span className={error ? styles.errorColor : styles.successColor}>
          {error}
        </span>
      )}
      <input
        type={fieldType}
        placeholder={placeHolder}
        className={fieldClasses.join(" ")}
        {...formContext.register(name, registerOptions)}
      />
    </div>
  );
};
