import { useEffect, useState } from "react";
import firebaseClient from "../core/firebase.config";
import { getDocs, Timestamp } from "firebase/firestore";
import { DEFAULT_PETER_DETAILS } from "../screens/Home/Home.utils";
import { ContactType } from "../screens/Home/ContactLink/ContactLink";

type Experience = {
  position: string;
  company: string;
  startDate: Timestamp;
  endDate?: Timestamp;
};

type Education = {
  program: string;
  institution: string;
  startDate: Timestamp;
  endDate: Timestamp;
  level: string;
};

type Certification = {
  name: string;
  issuer: string;
  issuedAt: Timestamp;
  link?: string;
};

export type ContactInfo = {
  name: string;
  type: ContactType;
  data: string;
};

export type PersonalDetailsResponse = {
  description: string;
  experiences: Experience[];
  educations: Education[];
  certifications: Certification[];
  contactDetails: ContactInfo[];
};

export const useGetPeterPersonalDetails = () => {
  const [personalDetails, setPersonalDetails] =
    useState<PersonalDetailsResponse | null>(null); // as of now any
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const personalDetailsCol = firebaseClient.getCollection("personal-details");
    getDocs(personalDetailsCol)
      .then((response) => {
        const responseData: PersonalDetailsResponse[] = response.docs.map(
          (doc) => doc.data()
        ) as unknown as PersonalDetailsResponse[];
        if (responseData.length > 0) {
          setPersonalDetails(responseData[0]);
        } else {
          setPersonalDetails(DEFAULT_PETER_DETAILS);
        }
      })
      .catch(() => {
        setPersonalDetails(DEFAULT_PETER_DETAILS);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { personalDetails, isLoading };
};
