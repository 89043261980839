import styles from "./BasicAreaTextField.module.scss";
import { BasicFreeTextFieldProps } from "../BasicFreeTextField/BasicFreeTextField";
import { useFormContext } from "react-hook-form";

type BasicAreaTextFieldProps = BasicFreeTextFieldProps & {
  // extends here
};

export const BasicAreaTextField = (props: BasicAreaTextFieldProps) => {
  const { name, placeHolder, registerOptions } = props;
  const formContext = useFormContext();
  const error = formContext.formState.errors[name]?.message?.toString() ?? "";
  const fieldClasses = [styles.textAreaField];

  if (error) {
    fieldClasses.push(styles.textAreaFieldValidationError);
  }

  return (
    <div className={styles.container}>
      {error && (
        <span className={error ? styles.errorColor : styles.successColor}>
          {error}
        </span>
      )}
      <textarea
        placeholder={placeHolder}
        className={fieldClasses.join(" ")}
        {...formContext.register(name, registerOptions)}
      ></textarea>
    </div>
  );
};
