import styles from "./Layout.module.scss";
import { TopAppBar } from "./TopAppBar/TopAppBar";
import { SideAppBar } from "./SideAppBar/SideAppBar";
import { useState } from "react";
import { Footer } from "./Footer/Footer";

type LayoutProps = {
  children?: any;
};

// TODO thing rework about the break points
// breakpoints: {
//   values: {
//     xs: 0,
//       sm: 720,
//       md: 900,
//       lg: 1200,
//       xl: 1536,
//   },
// },

export const Layout = (props: LayoutProps) => {
  const [isSideBarOpened, setIsSideBarOpened] = useState(false);

  const handleSideBarToggleClick = () => {
    setIsSideBarOpened((prevState) => !prevState);
  };

  const closeSideBar = () => {
    setIsSideBarOpened(false);
  };

  return (
    <div className={styles.container}>
      <div>
        <TopAppBar handleSideBarToggleClick={handleSideBarToggleClick} />
      </div>
      <SideAppBar
        closeSideBar={closeSideBar}
        isSideBarOpened={isSideBarOpened}
      />
      <div className={styles.mainContent}>{props.children}</div>
      <Footer />
    </div>
  );
};
