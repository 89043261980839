import styles from "./Backdrop.module.scss";

type BackdropProps = {
  show: boolean;
  closeBackdrop: () => void;
};

export const Backdrop = (props: BackdropProps) => {
  return props.show ? (
    <div className={styles.container} onClick={props.closeBackdrop}></div>
  ) : null;
};
