import { devEnv } from "./devEnvironement";

type FirebaseENvConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

type ENVIRONMENT = "development" | "production" | "test";

export type Environment = {
  apiUrl: string;
  firebaseConfig: FirebaseENvConfig;
};

export const getEnvironment = (): Environment => {
  const environment: ENVIRONMENT | undefined = process.env.NODE_ENV;
  switch (environment) {
    case "production": {
      return devEnv; // TODO change to prod end
    }
    default: {
      return devEnv;
    }
  }
};
