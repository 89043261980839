import styles from "./PortfolioRow.module.scss";
import { PortfolioDetails } from "../../screens/Home/Home.utils";

type PortfolioRowProps = {
  portfolioRowDetails: PortfolioDetails;
  isEvenRow: boolean;
  isFirstRow: boolean;
};

export const PortfolioRow = (props: PortfolioRowProps) => {
  const { portfolioRowDetails, isEvenRow } = props;

  return (
    <div
      className={isEvenRow ? styles.containerColumnReversed : styles.container}
    >
      {isEvenRow && (
        <div className={styles.imageContainer}>
          <img
            src={portfolioRowDetails.imageUrl}
            alt={portfolioRowDetails.title}
            className={styles.rowImage}
          />
          <a
            href={portfolioRowDetails.gitHubLink}
            className={styles.rowImageOverlay}
          >
            <div className={styles.rowImageOverlayText}>
              <h1>Go to Github</h1>
              <p>Branding, Development</p>
            </div>
          </a>
        </div>
      )}
      <div className={styles.descriptionContainer}>
        <h1 className={styles.rowTitle}>{portfolioRowDetails.title}</h1>
        <p>{portfolioRowDetails.description}</p>
      </div>

      {!isEvenRow && (
        <div className={styles.imageContainer}>
          <img
            src={portfolioRowDetails.imageUrl}
            alt={portfolioRowDetails.title}
            className={styles.rowImage}
          />
          <a
            href={portfolioRowDetails.gitHubLink}
            className={styles.rowImageOverlay}
          >
            <div className={styles.rowImageOverlayText}>
              <h1>Go to Github</h1>
              <p>Branding, Development</p>
            </div>
          </a>
        </div>
      )}
    </div>
  );
};
