import styles from "./Footer.module.scss";
import footerSvg from "../../../assests/home/footer.svg";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

type FooterContact = {
  name: string;
  legend: string;
  link: string;
  icon: any;
  styles?: string;
};

export const footerContactDetails: FooterContact[] = [
  {
    name: "github",
    legend: "Like us",
    link: "https://github.com/bke243",
    icon: <GitHubIcon />,
    styles: styles.gitHubIcon,
  },
  {
    name: "instagram",
    legend: "Instagram",
    link: "https://www.instagram.com/peterb_bk",
    icon: <InstagramIcon />,

    styles: styles.instagramIcon,
  },
  {
    name: "linkedin",
    legend: "Join us",
    link: "https://www.linkedin.com/in/peter-bilolo-badibake-a5bb26189/",
    icon: <LinkedInIcon />,

    styles: styles.linkedinIcons,
  },
  {
    name: "twitter",
    legend: "Follow us",
    link: "https://twitter.com/peterbbke",
    icon: <TwitterIcon />,

    styles: styles.twitterIcon,
  },
];

export const Footer = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={footerSvg} alt="footer border image" />
      </div>
      <div className={styles.socialMediaContainer}>
        <div>
          <h3>Find me in social media :</h3>
          <div className={styles.socialMediaIconContainer}>
            {footerContactDetails.map((footerContactDetail) => {
              return (
                <a
                  key={footerContactDetail.name}
                  className={[
                    styles.socialMediaIconItemContainer,
                    footerContactDetail.styles,
                  ].join(" ")}
                  href={footerContactDetail.link}
                  target={"_blank"}
                  rel={"referrer noreferrer"}
                >
                  {footerContactDetail.icon}
                </a>
              );
            })}
          </div>
        </div>
        <p className={styles.copyright}>Copyright 2020 Peter Bilolo Badibake</p>
      </div>
    </footer>
  );
};
