import styles from "./App.module.scss";
import { Layout } from "../Layout/Layout";
import { Routes, Route } from "react-router-dom";
import { getUserRoutesByRole } from "../../routes/routes";
import { PageNotFound } from "../../screens/PageNotFound/PageNotFound";

// TODO add a mechanism of recursion for nested routes or use query parameters (not the best but the simplest
const App = () => {
  const visitorUserRoutes = getUserRoutesByRole("Visitor");
  return (
    <div className={styles.container}>
      <Layout>
        <Routes>
          {visitorUserRoutes.map((visitorUserRoute) => {
            return (
              <Route
                path={visitorUserRoute.link}
                key={visitorUserRoute.name}
                element={visitorUserRoute.component}
              />
            );
          })}
          <Route
            path={"*"}
            key={"not found route"}
            element={<PageNotFound />}
          />
        </Routes>
      </Layout>
    </div>
  );
};

export default App;
