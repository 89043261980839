import { initializeApp, FirebaseApp } from "firebase/app";
import {
  getFirestore,
  Firestore,
  CollectionReference,
  doc,
  collection,
} from "firebase/firestore";
import { Environment, getEnvironment } from "../environments/environment";

class FirebaseAppClient {
  public currentEnvConfig: Environment = getEnvironment();
  private readonly firebaseApp: FirebaseApp;
  private readonly fireStore: Firestore;
  constructor() {
    this.firebaseApp = initializeApp({
      ...this.currentEnvConfig.firebaseConfig,
    });
    this.fireStore = getFirestore(this.firebaseApp);
  }

  getFireStore = (): Firestore => {
    return this.fireStore;
  };

  getFirebaseApp = (): FirebaseApp => {
    return this.firebaseApp;
  };

  getCollection = (collectionName: string): CollectionReference => {
    return collection(this.getFireStore(), collectionName);
  };

  getDocument = (collectionName: string, documentId: string) => {
    return doc(this.fireStore, collectionName, documentId);
  };
}

const firebaseAppClient = new FirebaseAppClient();

export default firebaseAppClient;
