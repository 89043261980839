import styles from "./Home.module.scss";
import arrowSvgIcon from "../../assests/home/aboutButton.svg";
import { ContactLink } from "./ContactLink/ContactLink";
import { HomeContactForm } from "./HomeContactForm/HomeContactForm";
import { PortfolioRow } from "../../components/PortfolioRow/PortfolioRow";
import { ServiceCard } from "./ServiceCard/ServiceCard";
import peterImage from "../../assests/home/personalPicture.jpg";
import { BasicButton } from "../../components/BasicButton/BasicButton";
import { useNavigate } from "react-router-dom";
import { useGetPeterPersonalDetails } from "../../api/getPeterPersonalDetails";
import { Timestamp } from "firebase/firestore";
import { useGetServices } from "../../api/getServices";
import { useGetProjects } from "../../api/getPrivateProjects";

const getMonthAndYear = (date: string | Timestamp | undefined) => {
  if (!date) return "-";
  const verifiedDate: any =
    typeof date === "string" ? new Date(date) : date.toDate();

  return `${
    verifiedDate.toUTCString().split(" ")[2]
  }. ${verifiedDate.getUTCFullYear()}`;
};

export const ABOUT_ME_ID = "about_me";
export const CONTACT_ME_ID = "contact_me";

export const scrollToContainer = (containerId: string) => {
  const containerDiv = document.getElementById(containerId);
  if (containerDiv) {
    window.scrollTo({ behavior: "smooth", top: containerDiv.offsetTop });
  }
};

export const Home = () => {
  const navigate = useNavigate();

  const getPeterPersonalDetailsRequest = useGetPeterPersonalDetails();
  const getServicesRequest = useGetServices();
  const getProjectRequest = useGetProjects();

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <div className={styles.textContainer}>
          <h1 className={styles.imageCaptionText}>
            Do you own a business?
            {/*there is a problem here digitize or digitalize ?? */}
            <br /> Would you like to Digitize it ? <br />
            Make your live easier and reach a wide audience
          </h1>
        </div>
        <div
          className={styles.captionButton}
          onClick={() => scrollToContainer(ABOUT_ME_ID)}
        >
          <img src={arrowSvgIcon} alt="DownButton" />
        </div>
      </div>
      <div className={styles.mainContentContainer}>
        <div className={styles.aboutContainer} id={ABOUT_ME_ID}>
          <div className={styles.aboutImageContainer}>
            <div className={styles.myImageContainer}>
              <img src={peterImage} alt={"My image"} />
            </div>
            <h4>Peter Bilolo Badibake</h4>
          </div>
          <div className={styles.aboutTextContainer}>
            <div className={styles.aboutTextPersonalDescription}>
              <h1 className={styles.aboutMeMainHeader}>About me:</h1>
              <p>
                {getPeterPersonalDetailsRequest.personalDetails?.description}
              </p>
            </div>
            <div className={styles.aboutTextAchievementContainer}>
              <div>
                <h1 className={styles.aboutMeHeader}>Work experience</h1>
                <ul className={styles.workExperienceList}>
                  {getPeterPersonalDetailsRequest.personalDetails?.experiences.map(
                    (experience) => {
                      return (
                        <li key={experience.position}>
                          <span>{experience.position}</span> <br />
                          <span
                            className={styles.workExperienceCompanyItem}
                          >{`${experience.company} (${getMonthAndYear(
                            experience.startDate
                          )}, ${getMonthAndYear(experience.endDate)})`}</span>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
              <div>
                <h1 className={styles.aboutMeHeader}>Education</h1>
                <ul className={styles.educationList}>
                  {getPeterPersonalDetailsRequest.personalDetails?.educations.map(
                    (education) => {
                      return (
                        <li key={education.program}>
                          <span>{education.program}</span> <br />
                          <span>
                            ({education.level},{" "}
                            {getMonthAndYear(education.endDate)})
                          </span>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
              <div>
                <h1 className={styles.aboutMeHeader}>Certificate</h1>
                <ul className={styles.certificateList}>
                  {getPeterPersonalDetailsRequest.personalDetails?.certifications.map(
                    (certification) => {
                      return (
                        <li key={certification.name}>
                          <span>{certification.name}</span> <br />
                          <span>
                            ({certification.issuer},
                            {getMonthAndYear(certification.issuedAt)})
                          </span>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.servicesContainer}>
          <h1 className={styles.sectionHeading}>Services:</h1>
          <div className={styles.servicesCardContainer}>
            {getServicesRequest.services?.map((service) => {
              return <ServiceCard key={service.title} {...service} />;
            })}
          </div>
        </div>
        <div className={styles.hireMeContainer}>
          <h2>Want do digitize your business ? </h2>
          <h3>I am available for freelancing</h3>
          <BasicButton
            btnText={"Hire me"}
            onButtonClickHandler={() => {
              scrollToContainer(CONTACT_ME_ID);
            }}
          />
        </div>
        <div className={styles.contactContainer}>
          <h1 className={styles.sectionHeading}>Contact:</h1>
          <div className={styles.contactDetailsContainer}>
            <div className={styles.contactInfo}>
              <div>
                {getPeterPersonalDetailsRequest.personalDetails?.contactDetails.map(
                  (contactDetails) => {
                    return (
                      <ContactLink
                        key={contactDetails.name}
                        contactDetails={contactDetails}
                      />
                    );
                  }
                )}
              </div>
              <div className={styles.contactMap}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d80194.00503145212!2d17.042818128207735!3d51.08885115895315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2spl!4v1599090282861!5m2!1sen!2spl"
                  allowFullScreen
                  title="City"
                  frameBorder={0}
                ></iframe>
              </div>
            </div>
            <div className={styles.contactForm}>
              <HomeContactForm />
            </div>
          </div>
        </div>
        <div className={styles.portfolioContainer}>
          <h1 className={styles.sectionHeading}>Portfolio:</h1>
          <div className={styles.portFolioRowsContainer}>
            {getProjectRequest.projects
              ?.slice(0, 2)
              .map((portfolioDetail, index) => {
                return (
                  <PortfolioRow
                    portfolioRowDetails={portfolioDetail}
                    isEvenRow={index % 2 === 0}
                    key={portfolioDetail.title}
                    isFirstRow={index === 0}
                  />
                );
              })}
          </div>
          <div className={styles.fullPortfolioBtnContainer}>
            <BasicButton
              btnText={"FULL PORTFOLIO"}
              onButtonClickHandler={() => {
                navigate("/portfolio");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
