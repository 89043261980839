import { Home } from "../screens/Home/Home";
import { Portfolio } from "../screens/Portfolio/Portfolio";
import homeLinkIcon from "../assests/home/house.svg";
import portfolioLinkIcon from "../assests/home/portfolioIcon.svg";
import { SvgIconImageContainer } from "../components/common/SvgIconImageContainer/SvgIconImageContainer";

export type Route = {
  name: string;
  component: JSX.Element;
  icon?: JSX.Element;
  link: string;
  isButton?: boolean;
};

// Admin is the app owner ( or any other with big privileges)
// Blogger any one else who can post stuff
// null, "",

export type UserRoles = "Admin" | "Blogger" | "Visitor";

// currently app support Home, Tutorials, Projects (or Not), Pricing, Peter Bke Pro  (find another name for pro)

const basicRoutes: Route[] = [
  {
    name: "Home",
    component: <Home />,
    link: "/",
    icon: <SvgIconImageContainer icon={homeLinkIcon} title={"home icon"} />,
  },
  {
    name: "Portfolio",
    component: <Portfolio />,
    link: "/portfolio",
    icon: (
      <SvgIconImageContainer
        icon={portfolioLinkIcon}
        title={"portfolio icon"}
      />
    ),
  },
  // {
  //   name: "Tutorials",
  //   component: <div>TODO Tutorial page</div>,
  //   link: "/tutorials",
  // },
  // {
  //   name: "XXX Pro",
  //   component: <div>TODO xxx Pro page</div>,
  //   link: "/xxx_pro",
  //   isButton: true,
  // },
];

const visitorRoutes: Route[] = [...basicRoutes];

const bloggerRoutes: Route[] = [
  ...basicRoutes,
  {
    name: "Articles",
    component: <div>TODO articles list with some filters (only yours)</div>,
    link: "/articles",
  },
  {
    name: "Create Article",
    component: <div>TODO article creation and edition page</div>,
    link: "/create_article",
  },
];

// admin (user management, .... )
export const getUserRoutesByRole = (userRole: UserRoles): Route[] => {
  if (userRole === "Visitor") {
    return visitorRoutes;
  } else if (userRole === "Blogger") {
    return bloggerRoutes;
  } else {
    return [];
  }
};
