import styles from "./TopAppBar.module.scss";
import { NavigationItems } from "../NavigationItems/NavigationItems";
import { getUserRoutesByRole } from "../../../routes/routes";
import { DrawerToggle } from "../DrawerToggle/DrawerToggle";
import { useNavigate } from "react-router-dom";

type TopAppBarProps = {
  handleSideBarToggleClick: () => void;
};

export const TopAppBar = (props: TopAppBarProps) => {
  // todo add expend when user roles will be integrated
  const userRoutes = getUserRoutesByRole("Visitor");
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer} onClick={() => navigate("/")}>
        <h4>
          Peter <br /> Bilolo Badibake
        </h4>
      </div>
      <DrawerToggle drawerToggleClick={props.handleSideBarToggleClick} />
      <div className={styles.navigationLinkContainer}>
        <NavigationItems navBarItems={userRoutes} />
      </div>
    </div>
  );
};
