import { useEffect, useState } from "react";
import firebaseAppClient from "../core/firebase.config";
import { getDocs } from "firebase/firestore";
import { DEFAULT_PROJECTS } from "../screens/Home/Home.utils";

type Project = {
  title: string;
  imageUrl: string;
  description: string;
  gitHubLink: string;
};

export type ProjectsResponse = Project[];

export const useGetProjects = () => {
  const [projects, setProjects] = useState<ProjectsResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const projectsCol = firebaseAppClient.getCollection("projects");
    getDocs(projectsCol)
      .then((projectsColResponse) => {
        const availableProjects = projectsColResponse.docs.map((projectItem) =>
          projectItem.data()
        ) as unknown as Project[];
        if (availableProjects.length > 0) {
          setProjects(availableProjects);
        } else {
          setProjects(DEFAULT_PROJECTS);
        }
      })
      .catch(() => {
        setProjects(DEFAULT_PROJECTS);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { projects, isLoading };
};
