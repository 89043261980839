import styles from "./Portfolio.module.scss";
import { PortfolioRow } from "../../components/PortfolioRow/PortfolioRow";
import { useGetProjects } from "../../api/getPrivateProjects";

export const Portfolio = () => {
  const getProjectRequest = useGetProjects();
  return (
    <div className={styles.container}>
      <div>
        {getProjectRequest.projects?.map((portfolioDetail, index) => {
          return (
            <PortfolioRow
              portfolioRowDetails={portfolioDetail}
              isEvenRow={index % 2 === 0}
              key={portfolioDetail.title}
              isFirstRow={index == 0}
            />
          );
        })}
      </div>
    </div>
  );
};
