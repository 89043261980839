import { getEnvironment } from "../environments/environment";

const url = `${getEnvironment().apiUrl}/message/send`;

export const usePostSendMessage = () => {
  return (message: any) => {
    return fetch(url, { body: JSON.stringify(message), method: "POST" }).then(
      (response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw response.json();
        }
      }
    );
  };
};
