import { PersonalDetailsResponse } from "../../api/getPeterPersonalDetails";
import { Timestamp } from "firebase/firestore";
import { ServicesResponse } from "../../api/getServices";
import { ProjectsResponse } from "../../api/getPrivateProjects";

// TODO change to fetch by API
export type PortfolioDetails = {
  title: string;
  imageUrl: string;
  description: string;
  gitHubLink?: string;
};

export const DEFAULT_PROJECTS: ProjectsResponse = [
  {
    title: "Online Shop",
    imageUrl:
      "https://images.unsplash.com/photo-1556745757-8d76bdb6984b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=966&q=80", //TODO add source
    description: `The project was realized using the following tech stack: express-js web application framework,
              MongoDB database, and ejs template engine. The main objective of the application was the implementation
              of functionalities such as user authentication, payment integration,
              image storage, dynamic pdf generation, and other security aspects such as csrf attacks ...
              `,
    gitHubLink: "https://github.com/bke243/expressjsWebAppSmallShop",
  },
  {
    title: "React Amazon clone",
    imageUrl: `https://images.unsplash.com/photo-1542744095-291d1f67b221?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80`,
    gitHubLink: "https://github.com/bke243/react-amazone-clone",
    description: `The project was realized using the following tech stack: Reactjs
              frontend application framework, Firebase for real-time database,
              React Context API  for state management and stripe functionalities to for payment, and firebase cloud function to process stripe payment (serverless) add more`,
  },
  {
    title: "Finance",
    imageUrl: `https://images.unsplash.com/photo-1563986768711-b3bde3dc821e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1048&q=80`,
    description: ` A small website that allows users to buy and sell stocks. the project was realized using the following tech stack:
               Flask framework, SQL Database, iex stocks cloud API, and Bootstrap template. the objective of the application was to simulate a trading website.
                when a user creates an account and login; he gets a certain amount of money that will allow him to buy, sell, and check the current price stocks in real-time
                 with the usage of iex API
              `,
    gitHubLink:
      "https://github.com/bke243/Havard-CS50--2019-Solutions/tree/master/Pset8/finance",
  },
  {
    title: "Personal website",
    gitHubLink: "https://github.com/bke243/spa-react-blog-beta",
    imageUrl: `https://cdn.pixabay.com/photo/2016/03/27/20/12/notebook-1284085_1280.jpg`,
    description: ` A  web application build with React front-end for the frontend and Nodejs as backend with the aim of providing myself a place where customer can reach me,
              and also allow me to write some articles about IT like a blog.
              although the portfolio part and the freelancing part have been released ; the new features will be coming soon. the project is developped using the  incremental software life cycle models.
              `,
  },
];

// DEFAULT SERVICES

export const DEFAULT_SERVICES: ServicesResponse = [
  {
    title: "Front-end Application",
    specifications: ["React", "Angular"],
    description:
      "Willing to have expertise in front-end application development? I can develop from scratch front-end applications that meet will your expectation or add new features to an existing project.",
  },
  {
    title: "Back-end Application",
    description:
      "Do you want to develop or add new services to an existing application? I can help you to achieve both depending on your requirements.",
    specifications: [
      "Python (Django)",
      "NodeJS (Express.js)",
      "NodeJS (Nest.js)",
    ],
  },
  {
    title: "Web Applications",
    specifications: ["MEAN", "MERN", "Django with React/ Angular"],
    description:
      "With the MERN / MEAN stack, I create lean, scalable, performant, secure, and user-friendly web applications that will meet all your needs.",
  },
  {
    title: "API Integration",
    specifications: ["API integration"],
    description:
      "Do you plan to serve your web application with data from multiple origins? let me help you through the API integration process securely.",
  },
];

// DEFAULT DETAILS
export const DEFAULT_PETER_DETAILS: PersonalDetailsResponse = {
  description:
    "Hello, My Name is Peter Bilolo Badibake. I am a software engineer based in Poland. I mainly work with the MERN / MEAN stack. As a quick learner, I can easily switch technologies depending on the project requirements. During my free time, I prefer reading books, drinking tea, doing sports, and exploring new tech trends to be updated. I am also a python lover.",
  contactDetails: [
    {
      name: "phone",
      type: "phone",
      data: "48********",
    },
    {
      name: "mail",
      type: "email",
      data: "peterbadibake@gmail.com",
    },
    {
      name: "map",
      type: "location",
      data: "wroclaw, Poland",
    },
  ],
  certifications: [
    {
      name: "Nodejs",
      issuedAt: Timestamp.fromDate(new Date(2020, 2)),
      issuer: "Academind",
    },
    {
      name: "CS50",
      issuedAt: Timestamp.fromDate(new Date(2019, 12)),
      issuer: "HAVARDx",
    },
  ],
  educations: [
    {
      program: "Applied Computer Science",
      startDate: Timestamp.fromDate(new Date(2018, 10)),
      endDate: Timestamp.fromDate(new Date(2022, 2)),
      institution: "Politechnika Wrocławska",
      level: "Bachelor",
    },
  ],
  experiences: [
    {
      company: "Freelancer",
      position: "Fullstack developer",
      startDate: Timestamp.fromDate(new Date(2020, 3)),
      endDate: Timestamp.fromDate(new Date(2020, 12)),
    },
    {
      company: "Nokia solutions and networks",
      position: "Frontend Developer",
      startDate: Timestamp.fromDate(new Date(2020, 12)),
      endDate: Timestamp.fromDate(new Date(2022, 2)),
    },
    {
      company: "EY GDS",
      position: "Frontend Engineer",
      startDate: Timestamp.fromDate(new Date(2022, 2)),
    },
  ],
};
