import styles from "./BasicButton.module.scss";

type BasicButtonProps = {
  btnText: string;
  onButtonClickHandler?: () => void;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
};

export const BasicButton = (props: BasicButtonProps) => {
  return (
    <button
      className={styles.btn}
      onClick={props.onButtonClickHandler}
      type={props.type}
      disabled={props.disabled}
    >
      {props.btnText}
    </button>
  );
};
