import styles from "./DrawerToggle.module.scss";

type DrawerToggleProps = {
  drawerToggleClick: () => void;
};

export const DrawerToggle = (props: DrawerToggleProps) => {
  return (
    <div className={styles.container} onClick={props.drawerToggleClick}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
