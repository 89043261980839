import styles from "./HomeContactForm.module.scss";
import { BasicFreeTextField } from "../../../components/FormFields/BasicFreeTextField/BasicFreeTextField";
import { BasicAreaTextField } from "../../../components/FormFields/BasicAreaTextField/BasicAreaTextField";
import { BasicButton } from "../../../components/BasicButton/BasicButton";
import { useForm, FormProvider } from "react-hook-form";
import { CONTACT_ME_ID } from "../Home";
import { usePostSendMessage } from "../../../api/postSendMessage";
import { useState } from "react";

type HomeMessage = {
  message: string;
  name: string;
  email: string;
};

const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const LETTER_ONLY_REGEX = /^[a-zA-Z\s]*$/;

export const HomeContactForm = () => {
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [messageResult, setMessageResult] = useState<
    null | "Success" | "Failure"
  >(null);
  const homeMessageForm = useForm<HomeMessage>({
    defaultValues: {
      message: "",
      name: "",
      email: "",
    },
    mode: "onSubmit",
  });

  const { handleSubmit } = homeMessageForm;
  const postMessageRequest = usePostSendMessage();
  const showMessageResultStatus = (messageStatus: "Failure" | "Success") => {
    setMessageResult(messageStatus);

    setTimeout(() => {
      setMessageResult(null);
    }, 3000);
  };

  const onSendMessageClick = (homeMessage: HomeMessage) => {
    setIsSendingMessage(true);
    postMessageRequest(homeMessage)
      .then(() => {
        showMessageResultStatus("Success");
        homeMessageForm.reset({
          message: "",
          name: "",
          email: "",
        });
      })
      .catch(() => {
        showMessageResultStatus("Failure");
      })
      .finally(() => {
        setIsSendingMessage(false);
      });
  };

  return (
    <div className={styles.container} id={CONTACT_ME_ID}>
      {messageResult && (
        <div
          className={`${styles.messageResultContainer} ${
            messageResult === "Failure"
              ? styles.errorMessageResult
              : styles.successMessageResult
          }`}
        >
          {messageResult === "Success"
            ? "Message sent !"
            : "Could not send your message !"}
        </div>
      )}
      <FormProvider {...homeMessageForm}>
        <div className={styles.fieldsContainer}>
          <div className={styles.inputFieldContainer}>
            <BasicFreeTextField
              name={"name"}
              placeHolder={"Your full name"}
              registerOptions={{
                maxLength: {
                  value: 50,
                  message: "Enter Name up to 50 characters",
                },
                required: { value: true, message: "Name is required" },
                pattern: {
                  value: LETTER_ONLY_REGEX,
                  message: "Please remove special characters",
                },
              }}
            />
            <BasicFreeTextField
              name={"email"}
              placeHolder={"Your email"}
              registerOptions={{
                required: { value: true, message: "Email is required" },
                pattern: {
                  value: EMAIL_VALIDATION_REGEX,
                  message: "Please enter a valid email",
                },
              }}
            />
          </div>
          <div className={styles.textAreaContainer}>
            <BasicAreaTextField
              name={"message"}
              placeHolder={"Your message"}
              registerOptions={{
                required: { value: true, message: "Message is required" },
                pattern: {
                  value: LETTER_ONLY_REGEX,
                  message: "Please remove special characters",
                },
                maxLength: {
                  value: 300,
                  message: "Enter a Message up to 50 characters",
                },
              }}
            />
          </div>
        </div>
      </FormProvider>
      <div className={styles.btnContainer}>
        <BasicButton
          btnText={"Send message"}
          type={"submit"}
          disabled={isSendingMessage}
          onButtonClickHandler={() => {
            handleSubmit(onSendMessageClick)();
          }}
        />
      </div>
    </div>
  );
};
